export const AddressSuggest = () => import('../../src/components/AddressSuggest.vue' /* webpackChunkName: "components/address-suggest" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../src/components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../src/components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Basket = () => import('../../src/components/atoms/Basket.vue' /* webpackChunkName: "components/basket" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../src/components/atoms/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ButtonTimeout = () => import('../../src/components/atoms/ButtonTimeout.vue' /* webpackChunkName: "components/button-timeout" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../src/components/atoms/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../src/components/atoms/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const EmptyProducts = () => import('../../src/components/atoms/EmptyProducts.vue' /* webpackChunkName: "components/empty-products" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../src/components/atoms/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../src/components/atoms/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../src/components/atoms/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const InputRange = () => import('../../src/components/atoms/InputRange.vue' /* webpackChunkName: "components/input-range" */).then(c => wrapFunctional(c.default || c))
export const InputSearch = () => import('../../src/components/atoms/InputSearch.vue' /* webpackChunkName: "components/input-search" */).then(c => wrapFunctional(c.default || c))
export const LinkComponent = () => import('../../src/components/atoms/LinkComponent.vue' /* webpackChunkName: "components/link-component" */).then(c => wrapFunctional(c.default || c))
export const ProductCounter = () => import('../../src/components/atoms/ProductCounter.vue' /* webpackChunkName: "components/product-counter" */).then(c => wrapFunctional(c.default || c))
export const ProductTab = () => import('../../src/components/atoms/ProductTab.vue' /* webpackChunkName: "components/product-tab" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../src/components/atoms/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const Warning = () => import('../../src/components/atoms/Warning.vue' /* webpackChunkName: "components/warning" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogo = () => import('../../src/components/atoms/headerLogo.vue' /* webpackChunkName: "components/header-logo" */).then(c => wrapFunctional(c.default || c))
export const InputRaiting = () => import('../../src/components/atoms/inputRaiting.vue' /* webpackChunkName: "components/input-raiting" */).then(c => wrapFunctional(c.default || c))
export const Textarea = () => import('../../src/components/atoms/textarea.vue' /* webpackChunkName: "components/textarea" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../src/components/moleculus/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CatalogMobilePagination = () => import('../../src/components/moleculus/CatalogMobilePagination.vue' /* webpackChunkName: "components/catalog-mobile-pagination" */).then(c => wrapFunctional(c.default || c))
export const CatalogPagination = () => import('../../src/components/moleculus/CatalogPagination.vue' /* webpackChunkName: "components/catalog-pagination" */).then(c => wrapFunctional(c.default || c))
export const ClientPagination = () => import('../../src/components/moleculus/ClientPagination.vue' /* webpackChunkName: "components/client-pagination" */).then(c => wrapFunctional(c.default || c))
export const ModalComponent = () => import('../../src/components/moleculus/ModalComponent.vue' /* webpackChunkName: "components/modal-component" */).then(c => wrapFunctional(c.default || c))
export const ThePagination = () => import('../../src/components/moleculus/ThePagination.vue' /* webpackChunkName: "components/the-pagination" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpand = () => import('../../src/components/moleculus/TransitionExpand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c))
export const AverageRaiting = () => import('../../src/components/moleculus/averageRaiting.vue' /* webpackChunkName: "components/average-raiting" */).then(c => wrapFunctional(c.default || c))
export const MainPageReviewItem = () => import('../../src/components/moleculus/mainPageReviewItem.vue' /* webpackChunkName: "components/main-page-review-item" */).then(c => wrapFunctional(c.default || c))
export const CartProduct = () => import('../../src/components/organisms/CartProduct.vue' /* webpackChunkName: "components/cart-product" */).then(c => wrapFunctional(c.default || c))
export const CatalogMenu = () => import('../../src/components/organisms/CatalogMenu.vue' /* webpackChunkName: "components/catalog-menu" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../src/components/organisms/banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const MainPageReviews = () => import('../../src/components/organisms/mainPageReviews.vue' /* webpackChunkName: "components/main-page-reviews" */).then(c => wrapFunctional(c.default || c))
export const TheAppReminder = () => import('../../src/components/ui/TheAppReminder.vue' /* webpackChunkName: "components/the-app-reminder" */).then(c => wrapFunctional(c.default || c))
export const FacebookIcon = () => import('../../src/components/atoms/svg/FacebookIcon.vue' /* webpackChunkName: "components/facebook-icon" */).then(c => wrapFunctional(c.default || c))
export const GoogleIcon = () => import('../../src/components/atoms/svg/GoogleIcon.vue' /* webpackChunkName: "components/google-icon" */).then(c => wrapFunctional(c.default || c))
export const InstagramIcon = () => import('../../src/components/atoms/svg/InstagramIcon.vue' /* webpackChunkName: "components/instagram-icon" */).then(c => wrapFunctional(c.default || c))
export const RFLogoIcon = () => import('../../src/components/atoms/svg/RFLogoIcon.vue' /* webpackChunkName: "components/r-f-logo-icon" */).then(c => wrapFunctional(c.default || c))
export const ReviewStarsIcon = () => import('../../src/components/atoms/svg/ReviewStarsIcon.vue' /* webpackChunkName: "components/review-stars-icon" */).then(c => wrapFunctional(c.default || c))
export const TelegramIcon = () => import('../../src/components/atoms/svg/TelegramIcon.vue' /* webpackChunkName: "components/telegram-icon" */).then(c => wrapFunctional(c.default || c))
export const VkIcon = () => import('../../src/components/atoms/svg/VkIcon.vue' /* webpackChunkName: "components/vk-icon" */).then(c => wrapFunctional(c.default || c))
export const ProfileConfirmEdit = () => import('../../src/components/moleculus/profile/ProfileConfirmEdit.vue' /* webpackChunkName: "components/profile-confirm-edit" */).then(c => wrapFunctional(c.default || c))
export const ReviewPageForm = () => import('../../src/components/moleculus/reviews/reviewPageForm.vue' /* webpackChunkName: "components/review-page-form" */).then(c => wrapFunctional(c.default || c))
export const ReviewPageItem = () => import('../../src/components/moleculus/reviews/reviewPageItem.vue' /* webpackChunkName: "components/review-page-item" */).then(c => wrapFunctional(c.default || c))
export const SigninAuthForm = () => import('../../src/components/moleculus/signin/SigninAuthForm.vue' /* webpackChunkName: "components/signin-auth-form" */).then(c => wrapFunctional(c.default || c))
export const SigninConfirmPhone = () => import('../../src/components/moleculus/signin/SigninConfirmPhone.vue' /* webpackChunkName: "components/signin-confirm-phone" */).then(c => wrapFunctional(c.default || c))
export const SigninNewAuthForm = () => import('../../src/components/moleculus/signin/SigninNewAuthForm.vue' /* webpackChunkName: "components/signin-new-auth-form" */).then(c => wrapFunctional(c.default || c))
export const SigninRegForm = () => import('../../src/components/moleculus/signin/SigninRegForm.vue' /* webpackChunkName: "components/signin-reg-form" */).then(c => wrapFunctional(c.default || c))
export const SigninRestorePassForm = () => import('../../src/components/moleculus/signin/SigninRestorePassForm.vue' /* webpackChunkName: "components/signin-restore-pass-form" */).then(c => wrapFunctional(c.default || c))
export const RemoveCardModal = () => import('../../src/components/organisms/card/RemoveCardModal.vue' /* webpackChunkName: "components/remove-card-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductConfirmModal = () => import('../../src/components/organisms/product/ProductConfirmModal.vue' /* webpackChunkName: "components/product-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const SigninModal = () => import('../../src/components/organisms/signin/SigninModal.vue' /* webpackChunkName: "components/signin-modal" */).then(c => wrapFunctional(c.default || c))
export const RoundFacebook = () => import('../../src/components/atoms/svg/roundSocIcons/RoundFacebook.vue' /* webpackChunkName: "components/round-facebook" */).then(c => wrapFunctional(c.default || c))
export const RoundTwitter = () => import('../../src/components/atoms/svg/roundSocIcons/RoundTwitter.vue' /* webpackChunkName: "components/round-twitter" */).then(c => wrapFunctional(c.default || c))
export const RoundVk = () => import('../../src/components/atoms/svg/roundSocIcons/RoundVk.vue' /* webpackChunkName: "components/round-vk" */).then(c => wrapFunctional(c.default || c))
export const RPHAvatar = () => import('../../src/components/v2/atoms/RPHAvatar/index.vue' /* webpackChunkName: "components/r-p-h-avatar" */).then(c => wrapFunctional(c.default || c))
export const RPHBadge = () => import('../../src/components/v2/atoms/RPHBadge/index.vue' /* webpackChunkName: "components/r-p-h-badge" */).then(c => wrapFunctional(c.default || c))
export const RPHButton = () => import('../../src/components/v2/atoms/RPHButton/index.vue' /* webpackChunkName: "components/r-p-h-button" */).then(c => wrapFunctional(c.default || c))
export const RPHButtonProduct = () => import('../../src/components/v2/atoms/RPHButtonProduct/index.vue' /* webpackChunkName: "components/r-p-h-button-product" */).then(c => wrapFunctional(c.default || c))
export const RPHCategoryLink = () => import('../../src/components/v2/atoms/RPHCategoryLink/index.vue' /* webpackChunkName: "components/r-p-h-category-link" */).then(c => wrapFunctional(c.default || c))
export const RPHContactData = () => import('../../src/components/v2/atoms/RPHContactData/index.vue' /* webpackChunkName: "components/r-p-h-contact-data" */).then(c => wrapFunctional(c.default || c))
export const RPHDeliveryInfo = () => import('../../src/components/v2/atoms/RPHDeliveryInfo/index.vue' /* webpackChunkName: "components/r-p-h-delivery-info" */).then(c => wrapFunctional(c.default || c))
export const RPHDeliveryNotification = () => import('../../src/components/v2/atoms/RPHDeliveryNotification/index.vue' /* webpackChunkName: "components/r-p-h-delivery-notification" */).then(c => wrapFunctional(c.default || c))
export const RPHDisclaimer = () => import('../../src/components/v2/atoms/RPHDisclaimer/index.vue' /* webpackChunkName: "components/r-p-h-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const RPHFooterSocialLink = () => import('../../src/components/v2/atoms/RPHFooterSocialLink/index.vue' /* webpackChunkName: "components/r-p-h-footer-social-link" */).then(c => wrapFunctional(c.default || c))
export const RPHHeaderBurger = () => import('../../src/components/v2/atoms/RPHHeaderBurger/index.vue' /* webpackChunkName: "components/r-p-h-header-burger" */).then(c => wrapFunctional(c.default || c))
export const RPHInput = () => import('../../src/components/v2/atoms/RPHInput/index.vue' /* webpackChunkName: "components/r-p-h-input" */).then(c => wrapFunctional(c.default || c))
export const RPHInputRange = () => import('../../src/components/v2/atoms/RPHInputRange/index.vue' /* webpackChunkName: "components/r-p-h-input-range" */).then(c => wrapFunctional(c.default || c))
export const RPHInputSearch = () => import('../../src/components/v2/atoms/RPHInputSearch/index.vue' /* webpackChunkName: "components/r-p-h-input-search" */).then(c => wrapFunctional(c.default || c))
export const RPHInputSearchPharmacy = () => import('../../src/components/v2/atoms/RPHInputSearchPharmacy/index.vue' /* webpackChunkName: "components/r-p-h-input-search-pharmacy" */).then(c => wrapFunctional(c.default || c))
export const RPHInputSearchPosition = () => import('../../src/components/v2/atoms/RPHInputSearchPosition/index.vue' /* webpackChunkName: "components/r-p-h-input-search-position" */).then(c => wrapFunctional(c.default || c))
export const RPHInputSearchTitle = () => import('../../src/components/v2/atoms/RPHInputSearchTitle/index.vue' /* webpackChunkName: "components/r-p-h-input-search-title" */).then(c => wrapFunctional(c.default || c))
export const RPHPaginationButton = () => import('../../src/components/v2/atoms/RPHPaginationButton/index.vue' /* webpackChunkName: "components/r-p-h-pagination-button" */).then(c => wrapFunctional(c.default || c))
export const RPHPaymentCard = () => import('../../src/components/v2/atoms/RPHPaymentCard/index.vue' /* webpackChunkName: "components/r-p-h-payment-card" */).then(c => wrapFunctional(c.default || c))
export const RPHPaymentItem = () => import('../../src/components/v2/atoms/RPHPaymentItem/index.vue' /* webpackChunkName: "components/r-p-h-payment-item" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyAddress = () => import('../../src/components/v2/atoms/RPHPharmacyAddress/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-address" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyContact = () => import('../../src/components/v2/atoms/RPHPharmacyContact/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-contact" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyReportButton = () => import('../../src/components/v2/atoms/RPHPharmacyReportButton/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-report-button" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacySocialLink = () => import('../../src/components/v2/atoms/RPHPharmacySocialLink/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-social-link" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyStatus = () => import('../../src/components/v2/atoms/RPHPharmacyStatus/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-status" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyWebsite = () => import('../../src/components/v2/atoms/RPHPharmacyWebsite/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-website" */).then(c => wrapFunctional(c.default || c))
export const RPHProductProducers = () => import('../../src/components/v2/atoms/RPHProductProducers/index.vue' /* webpackChunkName: "components/r-p-h-product-producers" */).then(c => wrapFunctional(c.default || c))
export const RPHRadio = () => import('../../src/components/v2/atoms/RPHRadio/index.vue' /* webpackChunkName: "components/r-p-h-radio" */).then(c => wrapFunctional(c.default || c))
export const RPHScheduleButton = () => import('../../src/components/v2/atoms/RPHScheduleButton/index.vue' /* webpackChunkName: "components/r-p-h-schedule-button" */).then(c => wrapFunctional(c.default || c))
export const RPHSortButton = () => import('../../src/components/v2/atoms/RPHSortButton/index.vue' /* webpackChunkName: "components/r-p-h-sort-button" */).then(c => wrapFunctional(c.default || c))
export const RPHStarRating = () => import('../../src/components/v2/atoms/RPHStarRating/index.vue' /* webpackChunkName: "components/r-p-h-star-rating" */).then(c => wrapFunctional(c.default || c))
export const RPHStatusBadge = () => import('../../src/components/v2/atoms/RPHStatusBadge/RPHStatusBadge.vue' /* webpackChunkName: "components/r-p-h-status-badge" */).then(c => wrapFunctional(c.default || c))
export const RPHTabBarItem = () => import('../../src/components/v2/atoms/RPHTabBarItem/index.vue' /* webpackChunkName: "components/r-p-h-tab-bar-item" */).then(c => wrapFunctional(c.default || c))
export const RPHTabButton = () => import('../../src/components/v2/atoms/RPHTabButton/index.vue' /* webpackChunkName: "components/r-p-h-tab-button" */).then(c => wrapFunctional(c.default || c))
export const RPHTableProduct = () => import('../../src/components/v2/atoms/RPHTableProduct/index.vue' /* webpackChunkName: "components/r-p-h-table-product" */).then(c => wrapFunctional(c.default || c))
export const RPHTags = () => import('../../src/components/v2/atoms/RPHTags/index.vue' /* webpackChunkName: "components/r-p-h-tags" */).then(c => wrapFunctional(c.default || c))
export const RPHTextBadge = () => import('../../src/components/v2/atoms/RPHTextBadge/index.vue' /* webpackChunkName: "components/r-p-h-text-badge" */).then(c => wrapFunctional(c.default || c))
export const RPHToggle = () => import('../../src/components/v2/atoms/RPHToggle/index.vue' /* webpackChunkName: "components/r-p-h-toggle" */).then(c => wrapFunctional(c.default || c))
export const RPHWarning = () => import('../../src/components/v2/atoms/RPHWarning/index.vue' /* webpackChunkName: "components/r-p-h-warning" */).then(c => wrapFunctional(c.default || c))
export const RPHWebsiteLink = () => import('../../src/components/v2/atoms/RPHWebsiteLink/index.vue' /* webpackChunkName: "components/r-p-h-website-link" */).then(c => wrapFunctional(c.default || c))
export const RPHAccordion = () => import('../../src/components/v2/molecules/RPHAccordion/index.vue' /* webpackChunkName: "components/r-p-h-accordion" */).then(c => wrapFunctional(c.default || c))
export const RPHAd = () => import('../../src/components/v2/molecules/RPHAd/index.vue' /* webpackChunkName: "components/r-p-h-ad" */).then(c => wrapFunctional(c.default || c))
export const RPHAddressSelect = () => import('../../src/components/v2/molecules/RPHAddressSelect/index.vue' /* webpackChunkName: "components/r-p-h-address-select" */).then(c => wrapFunctional(c.default || c))
export const RPHAlphabetFilter = () => import('../../src/components/v2/molecules/RPHAlphabetFilter/index.vue' /* webpackChunkName: "components/r-p-h-alphabet-filter" */).then(c => wrapFunctional(c.default || c))
export const RPHArticleCard = () => import('../../src/components/v2/molecules/RPHArticleCard/index.vue' /* webpackChunkName: "components/r-p-h-article-card" */).then(c => wrapFunctional(c.default || c))
export const RPHBalloonNearestShop = () => import('../../src/components/v2/molecules/RPHBalloonNearestShop/index.vue' /* webpackChunkName: "components/r-p-h-balloon-nearest-shop" */).then(c => wrapFunctional(c.default || c))
export const RPHBasketCart = () => import('../../src/components/v2/molecules/RPHBasketCart/index.vue' /* webpackChunkName: "components/r-p-h-basket-cart" */).then(c => wrapFunctional(c.default || c))
export const RPHCatalogCategories = () => import('../../src/components/v2/molecules/RPHCatalogCategories/index.vue' /* webpackChunkName: "components/r-p-h-catalog-categories" */).then(c => wrapFunctional(c.default || c))
export const RPHCatalogList = () => import('../../src/components/v2/molecules/RPHCatalogList/index.vue' /* webpackChunkName: "components/r-p-h-catalog-list" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutData = () => import('../../src/components/v2/molecules/RPHCheckoutData/index.vue' /* webpackChunkName: "components/r-p-h-checkout-data" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutMobileHeader = () => import('../../src/components/v2/molecules/RPHCheckoutMobileHeader/index.vue' /* webpackChunkName: "components/r-p-h-checkout-mobile-header" */).then(c => wrapFunctional(c.default || c))
export const RPHCombineCart = () => import('../../src/components/v2/molecules/RPHCombineCart/index.vue' /* webpackChunkName: "components/r-p-h-combine-cart" */).then(c => wrapFunctional(c.default || c))
export const RPHContactMap = () => import('../../src/components/v2/molecules/RPHContactMap/index.vue' /* webpackChunkName: "components/r-p-h-contact-map" */).then(c => wrapFunctional(c.default || c))
export const RPHDiseasCategories = () => import('../../src/components/v2/molecules/RPHDiseasCategories/index.vue' /* webpackChunkName: "components/r-p-h-diseas-categories" */).then(c => wrapFunctional(c.default || c))
export const RPHDiseaseCard = () => import('../../src/components/v2/molecules/RPHDiseaseCard/index.vue' /* webpackChunkName: "components/r-p-h-disease-card" */).then(c => wrapFunctional(c.default || c))
export const RPHEmptyBasket = () => import('../../src/components/v2/molecules/RPHEmptyBasket/index.vue' /* webpackChunkName: "components/r-p-h-empty-basket" */).then(c => wrapFunctional(c.default || c))
export const RPHFilterCard = () => import('../../src/components/v2/molecules/RPHFilterCard/index.vue' /* webpackChunkName: "components/r-p-h-filter-card" */).then(c => wrapFunctional(c.default || c))
export const RPHFilterPrice = () => import('../../src/components/v2/molecules/RPHFilterPrice/index.vue' /* webpackChunkName: "components/r-p-h-filter-price" */).then(c => wrapFunctional(c.default || c))
export const RPHFilterTabs = () => import('../../src/components/v2/molecules/RPHFilterTabs/index.vue' /* webpackChunkName: "components/r-p-h-filter-tabs" */).then(c => wrapFunctional(c.default || c))
export const RPHFooterContacts = () => import('../../src/components/v2/molecules/RPHFooterContacts/index.vue' /* webpackChunkName: "components/r-p-h-footer-contacts" */).then(c => wrapFunctional(c.default || c))
export const RPHFooterNav = () => import('../../src/components/v2/molecules/RPHFooterNav/index.vue' /* webpackChunkName: "components/r-p-h-footer-nav" */).then(c => wrapFunctional(c.default || c))
export const RPHFooterSocials = () => import('../../src/components/v2/molecules/RPHFooterSocials/index.vue' /* webpackChunkName: "components/r-p-h-footer-socials" */).then(c => wrapFunctional(c.default || c))
export const RPHHeaderBasket = () => import('../../src/components/v2/molecules/RPHHeaderBasket/index.vue' /* webpackChunkName: "components/r-p-h-header-basket" */).then(c => wrapFunctional(c.default || c))
export const RPHHeaderLocationButton = () => import('../../src/components/v2/molecules/RPHHeaderLocationButton/index.vue' /* webpackChunkName: "components/r-p-h-header-location-button" */).then(c => wrapFunctional(c.default || c))
export const RPHHeaderNav = () => import('../../src/components/v2/molecules/RPHHeaderNav/index.vue' /* webpackChunkName: "components/r-p-h-header-nav" */).then(c => wrapFunctional(c.default || c))
export const RPHHeaderProfileControl = () => import('../../src/components/v2/molecules/RPHHeaderProfileControl/index.vue' /* webpackChunkName: "components/r-p-h-header-profile-control" */).then(c => wrapFunctional(c.default || c))
export const RPHHeaderWhatsApp = () => import('../../src/components/v2/molecules/RPHHeaderWhatsApp/index.vue' /* webpackChunkName: "components/r-p-h-header-whats-app" */).then(c => wrapFunctional(c.default || c))
export const RPHInstitutionMenu = () => import('../../src/components/v2/molecules/RPHInstitutionMenu/index.vue' /* webpackChunkName: "components/r-p-h-institution-menu" */).then(c => wrapFunctional(c.default || c))
export const RPHLargeBanner = () => import('../../src/components/v2/molecules/RPHLargeBanner/index.vue' /* webpackChunkName: "components/r-p-h-large-banner" */).then(c => wrapFunctional(c.default || c))
export const RPHMapNearestShops = () => import('../../src/components/v2/molecules/RPHMapNearestShops/index.vue' /* webpackChunkName: "components/r-p-h-map-nearest-shops" */).then(c => wrapFunctional(c.default || c))
export const RPHNewArticles = () => import('../../src/components/v2/molecules/RPHNewArticles/index.vue' /* webpackChunkName: "components/r-p-h-new-articles" */).then(c => wrapFunctional(c.default || c))
export const RPHOrderCard = () => import('../../src/components/v2/molecules/RPHOrderCard/index.vue' /* webpackChunkName: "components/r-p-h-order-card" */).then(c => wrapFunctional(c.default || c))
export const RPHOrderMethodCard = () => import('../../src/components/v2/molecules/RPHOrderMethodCard/index.vue' /* webpackChunkName: "components/r-p-h-order-method-card" */).then(c => wrapFunctional(c.default || c))
export const RPHPagination = () => import('../../src/components/v2/molecules/RPHPagination/index.vue' /* webpackChunkName: "components/r-p-h-pagination" */).then(c => wrapFunctional(c.default || c))
export const RPHPaymentCards = () => import('../../src/components/v2/molecules/RPHPaymentCards/index.vue' /* webpackChunkName: "components/r-p-h-payment-cards" */).then(c => wrapFunctional(c.default || c))
export const RPHPaymentForm = () => import('../../src/components/v2/molecules/RPHPaymentForm/index.vue' /* webpackChunkName: "components/r-p-h-payment-form" */).then(c => wrapFunctional(c.default || c))
export const RPHPaymentSecure = () => import('../../src/components/v2/molecules/RPHPaymentSecure/index.vue' /* webpackChunkName: "components/r-p-h-payment-secure" */).then(c => wrapFunctional(c.default || c))
export const RPHPayments = () => import('../../src/components/v2/molecules/RPHPayments/index.vue' /* webpackChunkName: "components/r-p-h-payments" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyBody = () => import('../../src/components/v2/molecules/RPHPharmacyBody/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-body" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyButtonGroup = () => import('../../src/components/v2/molecules/RPHPharmacyButtonGroup/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-button-group" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyCard = () => import('../../src/components/v2/molecules/RPHPharmacyCard/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-card" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyCardButtons = () => import('../../src/components/v2/molecules/RPHPharmacyCardButtons/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-card-buttons" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyFilter = () => import('../../src/components/v2/molecules/RPHPharmacyFilter/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-filter" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyMenu = () => import('../../src/components/v2/molecules/RPHPharmacyMenu/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-menu" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacySocials = () => import('../../src/components/v2/molecules/RPHPharmacySocials/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-socials" */).then(c => wrapFunctional(c.default || c))
export const RPHPopularProductCard = () => import('../../src/components/v2/molecules/RPHPopularProductCard/index.vue' /* webpackChunkName: "components/r-p-h-popular-product-card" */).then(c => wrapFunctional(c.default || c))
export const RPHProductAnalog = () => import('../../src/components/v2/molecules/RPHProductAnalog/index.vue' /* webpackChunkName: "components/r-p-h-product-analog" */).then(c => wrapFunctional(c.default || c))
export const RPHProductCard = () => import('../../src/components/v2/molecules/RPHProductCard/index.vue' /* webpackChunkName: "components/r-p-h-product-card" */).then(c => wrapFunctional(c.default || c))
export const RPHProductDeliveryTable = () => import('../../src/components/v2/molecules/RPHProductDeliveryTable/index.vue' /* webpackChunkName: "components/r-p-h-product-delivery-table" */).then(c => wrapFunctional(c.default || c))
export const RPHProductExpiration = () => import('../../src/components/v2/molecules/RPHProductExpiration/index.vue' /* webpackChunkName: "components/r-p-h-product-expiration" */).then(c => wrapFunctional(c.default || c))
export const RPHProductFilter = () => import('../../src/components/v2/molecules/RPHProductFilter/index.vue' /* webpackChunkName: "components/r-p-h-product-filter" */).then(c => wrapFunctional(c.default || c))
export const RPHProductGallery = () => import('../../src/components/v2/molecules/RPHProductGallery/index.vue' /* webpackChunkName: "components/r-p-h-product-gallery" */).then(c => wrapFunctional(c.default || c))
export const RPHProductMnns = () => import('../../src/components/v2/molecules/RPHProductMnns/index.vue' /* webpackChunkName: "components/r-p-h-product-mnns" */).then(c => wrapFunctional(c.default || c))
export const RPHProductPrice = () => import('../../src/components/v2/molecules/RPHProductPrice/index.vue' /* webpackChunkName: "components/r-p-h-product-price" */).then(c => wrapFunctional(c.default || c))
export const RPHProductSort = () => import('../../src/components/v2/molecules/RPHProductSort/index.vue' /* webpackChunkName: "components/r-p-h-product-sort" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileAddressCard = () => import('../../src/components/v2/molecules/RPHProfileAddressCard/index.vue' /* webpackChunkName: "components/r-p-h-profile-address-card" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileCard = () => import('../../src/components/v2/molecules/RPHProfileCard/index.vue' /* webpackChunkName: "components/r-p-h-profile-card" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileOrderCard = () => import('../../src/components/v2/molecules/RPHProfileOrderCard/index.vue' /* webpackChunkName: "components/r-p-h-profile-order-card" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileUploader = () => import('../../src/components/v2/molecules/RPHProfileUploader/index.vue' /* webpackChunkName: "components/r-p-h-profile-uploader" */).then(c => wrapFunctional(c.default || c))
export const RPHRecipeCard = () => import('../../src/components/v2/molecules/RPHRecipeCard/index.vue' /* webpackChunkName: "components/r-p-h-recipe-card" */).then(c => wrapFunctional(c.default || c))
export const RPHReviewCard = () => import('../../src/components/v2/molecules/RPHReviewCard/index.vue' /* webpackChunkName: "components/r-p-h-review-card" */).then(c => wrapFunctional(c.default || c))
export const RPHRowCard = () => import('../../src/components/v2/molecules/RPHRowCard/index.vue' /* webpackChunkName: "components/r-p-h-row-card" */).then(c => wrapFunctional(c.default || c))
export const RPHScheduleGrid = () => import('../../src/components/v2/molecules/RPHScheduleGrid/index.vue' /* webpackChunkName: "components/r-p-h-schedule-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHSearchResult = () => import('../../src/components/v2/molecules/RPHSearchResult/index.vue' /* webpackChunkName: "components/r-p-h-search-result" */).then(c => wrapFunctional(c.default || c))
export const RPHSelect = () => import('../../src/components/v2/molecules/RPHSelect/index.vue' /* webpackChunkName: "components/r-p-h-select" */).then(c => wrapFunctional(c.default || c))
export const RPHSelfDelivery = () => import('../../src/components/v2/molecules/RPHSelfDelivery/index.vue' /* webpackChunkName: "components/r-p-h-self-delivery" */).then(c => wrapFunctional(c.default || c))
export const RPHShareOfSocials = () => import('../../src/components/v2/molecules/RPHShareOfSocials/index.vue' /* webpackChunkName: "components/r-p-h-share-of-socials" */).then(c => wrapFunctional(c.default || c))
export const RPHSmallBanner = () => import('../../src/components/v2/molecules/RPHSmallBanner/index.vue' /* webpackChunkName: "components/r-p-h-small-banner" */).then(c => wrapFunctional(c.default || c))
export const RPHTabBar = () => import('../../src/components/v2/molecules/RPHTabBar/index.vue' /* webpackChunkName: "components/r-p-h-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const RPHTextCollapsible = () => import('../../src/components/v2/molecules/RPHTextCollapsible/index.vue' /* webpackChunkName: "components/r-p-h-text-collapsible" */).then(c => wrapFunctional(c.default || c))
export const RPHToast = () => import('../../src/components/v2/molecules/RPHToast/index.vue' /* webpackChunkName: "components/r-p-h-toast" */).then(c => wrapFunctional(c.default || c))
export const RPHAlphabetContainer = () => import('../../src/components/v2/organisms/RPHAlphabetContainer/index.vue' /* webpackChunkName: "components/r-p-h-alphabet-container" */).then(c => wrapFunctional(c.default || c))
export const RPHAnalogsGrid = () => import('../../src/components/v2/organisms/RPHAnalogsGrid/index.vue' /* webpackChunkName: "components/r-p-h-analogs-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHAnalogsList = () => import('../../src/components/v2/organisms/RPHAnalogsList/index.vue' /* webpackChunkName: "components/r-p-h-analogs-list" */).then(c => wrapFunctional(c.default || c))
export const RPHAppReminder = () => import('../../src/components/v2/organisms/RPHAppReminder/index.vue' /* webpackChunkName: "components/r-p-h-app-reminder" */).then(c => wrapFunctional(c.default || c))
export const RPHArticlesGrid = () => import('../../src/components/v2/organisms/RPHArticlesGrid/index.vue' /* webpackChunkName: "components/r-p-h-articles-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHBasketContainer = () => import('../../src/components/v2/organisms/RPHBasketContainer/index.vue' /* webpackChunkName: "components/r-p-h-basket-container" */).then(c => wrapFunctional(c.default || c))
export const RPHBasketList = () => import('../../src/components/v2/organisms/RPHBasketList/index.vue' /* webpackChunkName: "components/r-p-h-basket-list" */).then(c => wrapFunctional(c.default || c))
export const RPHBurgerMenu = () => import('../../src/components/v2/organisms/RPHBurgerMenu/index.vue' /* webpackChunkName: "components/r-p-h-burger-menu" */).then(c => wrapFunctional(c.default || c))
export const RPHCardsGrid = () => import('../../src/components/v2/organisms/RPHCardsGrid/index.vue' /* webpackChunkName: "components/r-p-h-cards-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHCatalogProductsGrid = () => import('../../src/components/v2/organisms/RPHCatalogProductsGrid/index.vue' /* webpackChunkName: "components/r-p-h-catalog-products-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutAddress = () => import('../../src/components/v2/organisms/RPHCheckoutAddress/index.vue' /* webpackChunkName: "components/r-p-h-checkout-address" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutCommentForm = () => import('../../src/components/v2/organisms/RPHCheckoutCommentForm/index.vue' /* webpackChunkName: "components/r-p-h-checkout-comment-form" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutContactForm = () => import('../../src/components/v2/organisms/RPHCheckoutContactForm/index.vue' /* webpackChunkName: "components/r-p-h-checkout-contact-form" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutContactInfo = () => import('../../src/components/v2/organisms/RPHCheckoutContactInfo/index.vue' /* webpackChunkName: "components/r-p-h-checkout-contact-info" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutHeader = () => import('../../src/components/v2/organisms/RPHCheckoutHeader/index.vue' /* webpackChunkName: "components/r-p-h-checkout-header" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutRecipeForm = () => import('../../src/components/v2/organisms/RPHCheckoutRecipeForm/index.vue' /* webpackChunkName: "components/r-p-h-checkout-recipe-form" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutSchedule = () => import('../../src/components/v2/organisms/RPHCheckoutSchedule/index.vue' /* webpackChunkName: "components/r-p-h-checkout-schedule" */).then(c => wrapFunctional(c.default || c))
export const RPHContactForm = () => import('../../src/components/v2/organisms/RPHContactForm/index.vue' /* webpackChunkName: "components/r-p-h-contact-form" */).then(c => wrapFunctional(c.default || c))
export const RPHContactsDetails = () => import('../../src/components/v2/organisms/RPHContactsDetails/index.vue' /* webpackChunkName: "components/r-p-h-contacts-details" */).then(c => wrapFunctional(c.default || c))
export const RPHDiseaseList = () => import('../../src/components/v2/organisms/RPHDiseaseList/index.vue' /* webpackChunkName: "components/r-p-h-disease-list" */).then(c => wrapFunctional(c.default || c))
export const RPHFaqAsideSelect = () => import('../../src/components/v2/organisms/RPHFaqAsideSelect/index.vue' /* webpackChunkName: "components/r-p-h-faq-aside-select" */).then(c => wrapFunctional(c.default || c))
export const RPHFaqForm = () => import('../../src/components/v2/organisms/RPHFaqForm/index.vue' /* webpackChunkName: "components/r-p-h-faq-form" */).then(c => wrapFunctional(c.default || c))
export const RPHFaqList = () => import('../../src/components/v2/organisms/RPHFaqList/index.vue' /* webpackChunkName: "components/r-p-h-faq-list" */).then(c => wrapFunctional(c.default || c))
export const RPHFilterCatalog = () => import('../../src/components/v2/organisms/RPHFilterCatalog/index.vue' /* webpackChunkName: "components/r-p-h-filter-catalog" */).then(c => wrapFunctional(c.default || c))
export const RPHFilterModal = () => import('../../src/components/v2/organisms/RPHFilterModal/index.vue' /* webpackChunkName: "components/r-p-h-filter-modal" */).then(c => wrapFunctional(c.default || c))
export const RPHHeaderButtonGroup = () => import('../../src/components/v2/organisms/RPHHeaderButtonGroup/index.vue' /* webpackChunkName: "components/r-p-h-header-button-group" */).then(c => wrapFunctional(c.default || c))
export const RPHHeaderLocation = () => import('../../src/components/v2/organisms/RPHHeaderLocation/index.vue' /* webpackChunkName: "components/r-p-h-header-location" */).then(c => wrapFunctional(c.default || c))
export const RPHLargeBannerGrid = () => import('../../src/components/v2/organisms/RPHLargeBannerGrid/index.vue' /* webpackChunkName: "components/r-p-h-large-banner-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHNearestProductsGrid = () => import('../../src/components/v2/organisms/RPHNearestProductsGrid/index.vue' /* webpackChunkName: "components/r-p-h-nearest-products-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHNewArticlesGrid = () => import('../../src/components/v2/organisms/RPHNewArticlesGrid/index.vue' /* webpackChunkName: "components/r-p-h-new-articles-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHNewsReviewBlock = () => import('../../src/components/v2/organisms/RPHNewsReviewBlock/index.vue' /* webpackChunkName: "components/r-p-h-news-review-block" */).then(c => wrapFunctional(c.default || c))
export const RPHNotificationForm = () => import('../../src/components/v2/organisms/RPHNotificationForm/index.vue' /* webpackChunkName: "components/r-p-h-notification-form" */).then(c => wrapFunctional(c.default || c))
export const RPHOrderMethods = () => import('../../src/components/v2/organisms/RPHOrderMethods/index.vue' /* webpackChunkName: "components/r-p-h-order-methods" */).then(c => wrapFunctional(c.default || c))
export const RPHOrderRenderCards = () => import('../../src/components/v2/organisms/RPHOrderRenderCards/index.vue' /* webpackChunkName: "components/r-p-h-order-render-cards" */).then(c => wrapFunctional(c.default || c))
export const RPHPaymentMethods = () => import('../../src/components/v2/organisms/RPHPaymentMethods/index.vue' /* webpackChunkName: "components/r-p-h-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmaciesGrid = () => import('../../src/components/v2/organisms/RPHPharmaciesGrid/index.vue' /* webpackChunkName: "components/r-p-h-pharmacies-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmaciesSearch = () => import('../../src/components/v2/organisms/RPHPharmaciesSearch/index.vue' /* webpackChunkName: "components/r-p-h-pharmacies-search" */).then(c => wrapFunctional(c.default || c))
export const RPHPopularProductGrid = () => import('../../src/components/v2/organisms/RPHPopularProductGrid/index.vue' /* webpackChunkName: "components/r-p-h-popular-product-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHProductDetails = () => import('../../src/components/v2/organisms/RPHProductDetails/index.vue' /* webpackChunkName: "components/r-p-h-product-details" */).then(c => wrapFunctional(c.default || c))
export const RPHProductSpecification = () => import('../../src/components/v2/organisms/RPHProductSpecification/index.vue' /* webpackChunkName: "components/r-p-h-product-specification" */).then(c => wrapFunctional(c.default || c))
export const RPHProductSpecs = () => import('../../src/components/v2/organisms/RPHProductSpecs/index.vue' /* webpackChunkName: "components/r-p-h-product-specs" */).then(c => wrapFunctional(c.default || c))
export const RPHProfile = () => import('../../src/components/v2/organisms/RPHProfile/index.vue' /* webpackChunkName: "components/r-p-h-profile" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileAddressForm = () => import('../../src/components/v2/organisms/RPHProfileAddressForm/index.vue' /* webpackChunkName: "components/r-p-h-profile-address-form" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileAddressGrid = () => import('../../src/components/v2/organisms/RPHProfileAddressGrid/index.vue' /* webpackChunkName: "components/r-p-h-profile-address-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileEdit = () => import('../../src/components/v2/organisms/RPHProfileEdit/index.vue' /* webpackChunkName: "components/r-p-h-profile-edit" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileForm = () => import('../../src/components/v2/organisms/RPHProfileForm/index.vue' /* webpackChunkName: "components/r-p-h-profile-form" */).then(c => wrapFunctional(c.default || c))
export const RPHRecipeGrid = () => import('../../src/components/v2/organisms/RPHRecipeGrid/index.vue' /* webpackChunkName: "components/r-p-h-recipe-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHReviewBlock = () => import('../../src/components/v2/organisms/RPHReviewBlock/index.vue' /* webpackChunkName: "components/r-p-h-review-block" */).then(c => wrapFunctional(c.default || c))
export const RPHRowsList = () => import('../../src/components/v2/organisms/RPHRowsList/index.vue' /* webpackChunkName: "components/r-p-h-rows-list" */).then(c => wrapFunctional(c.default || c))
export const RPHSearchBar = () => import('../../src/components/v2/organisms/RPHSearchBar/index.vue' /* webpackChunkName: "components/r-p-h-search-bar" */).then(c => wrapFunctional(c.default || c))
export const RPHSmallBannerGrid = () => import('../../src/components/v2/organisms/RPHSmallBannerGrid/index.vue' /* webpackChunkName: "components/r-p-h-small-banner-grid" */).then(c => wrapFunctional(c.default || c))
export const RPHTabsList = () => import('../../src/components/v2/organisms/RPHTabsList/index.vue' /* webpackChunkName: "components/r-p-h-tabs-list" */).then(c => wrapFunctional(c.default || c))
export const RPHToastContainer = () => import('../../src/components/v2/organisms/RPHToastContainer/index.vue' /* webpackChunkName: "components/r-p-h-toast-container" */).then(c => wrapFunctional(c.default || c))
export const RPHAbout = () => import('../../src/components/v2/templates/RPHAbout/index.vue' /* webpackChunkName: "components/r-p-h-about" */).then(c => wrapFunctional(c.default || c))
export const RPHAboutBlock = () => import('../../src/components/v2/templates/RPHAboutBlock/index.vue' /* webpackChunkName: "components/r-p-h-about-block" */).then(c => wrapFunctional(c.default || c))
export const RPHAnalogs = () => import('../../src/components/v2/templates/RPHAnalogs/index.vue' /* webpackChunkName: "components/r-p-h-analogs" */).then(c => wrapFunctional(c.default || c))
export const RPHArticlesBlock = () => import('../../src/components/v2/templates/RPHArticlesBlock/index.vue' /* webpackChunkName: "components/r-p-h-articles-block" */).then(c => wrapFunctional(c.default || c))
export const RPHBasketHeader = () => import('../../src/components/v2/templates/RPHBasketHeader/index.vue' /* webpackChunkName: "components/r-p-h-basket-header" */).then(c => wrapFunctional(c.default || c))
export const RPHCabinet = () => import('../../src/components/v2/templates/RPHCabinet/index.vue' /* webpackChunkName: "components/r-p-h-cabinet" */).then(c => wrapFunctional(c.default || c))
export const RPHCartTemplate = () => import('../../src/components/v2/templates/RPHCartTemplate/index.vue' /* webpackChunkName: "components/r-p-h-cart-template" */).then(c => wrapFunctional(c.default || c))
export const RPHCatalog = () => import('../../src/components/v2/templates/RPHCatalog/index.vue' /* webpackChunkName: "components/r-p-h-catalog" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckout = () => import('../../src/components/v2/templates/RPHCheckout/index.vue' /* webpackChunkName: "components/r-p-h-checkout" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutError = () => import('../../src/components/v2/templates/RPHCheckoutError/index.vue' /* webpackChunkName: "components/r-p-h-checkout-error" */).then(c => wrapFunctional(c.default || c))
export const RPHCheckoutSuccess = () => import('../../src/components/v2/templates/RPHCheckoutSuccess/index.vue' /* webpackChunkName: "components/r-p-h-checkout-success" */).then(c => wrapFunctional(c.default || c))
export const RPHContacts = () => import('../../src/components/v2/templates/RPHContacts/index.vue' /* webpackChunkName: "components/r-p-h-contacts" */).then(c => wrapFunctional(c.default || c))
export const RPHDiseasesContainer = () => import('../../src/components/v2/templates/RPHDiseasesContainer/index.vue' /* webpackChunkName: "components/r-p-h-diseases-container" */).then(c => wrapFunctional(c.default || c))
export const RPHErrorPage = () => import('../../src/components/v2/templates/RPHErrorPage/index.vue' /* webpackChunkName: "components/r-p-h-error-page" */).then(c => wrapFunctional(c.default || c))
export const RPHFaqContent = () => import('../../src/components/v2/templates/RPHFaqContent/index.vue' /* webpackChunkName: "components/r-p-h-faq-content" */).then(c => wrapFunctional(c.default || c))
export const RPHFooter = () => import('../../src/components/v2/templates/RPHFooter/index.vue' /* webpackChunkName: "components/r-p-h-footer" */).then(c => wrapFunctional(c.default || c))
export const RPHHeader = () => import('../../src/components/v2/templates/RPHHeader/index.vue' /* webpackChunkName: "components/r-p-h-header" */).then(c => wrapFunctional(c.default || c))
export const RPHHeaderMobile = () => import('../../src/components/v2/templates/RPHHeaderMobile/index.vue' /* webpackChunkName: "components/r-p-h-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const RPHInnHeader = () => import('../../src/components/v2/templates/RPHInnHeader/index.vue' /* webpackChunkName: "components/r-p-h-inn-header" */).then(c => wrapFunctional(c.default || c))
export const RPHModalContainer = () => import('../../src/components/v2/templates/RPHModalContainer/index.vue' /* webpackChunkName: "components/r-p-h-modal-container" */).then(c => wrapFunctional(c.default || c))
export const RPHNearestProductsBlock = () => import('../../src/components/v2/templates/RPHNearestProductsBlock/index.vue' /* webpackChunkName: "components/r-p-h-nearest-products-block" */).then(c => wrapFunctional(c.default || c))
export const RPHNews = () => import('../../src/components/v2/templates/RPHNews/index.vue' /* webpackChunkName: "components/r-p-h-news" */).then(c => wrapFunctional(c.default || c))
export const RPHNewsHeader = () => import('../../src/components/v2/templates/RPHNewsHeader/index.vue' /* webpackChunkName: "components/r-p-h-news-header" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmaciesList = () => import('../../src/components/v2/templates/RPHPharmaciesList/index.vue' /* webpackChunkName: "components/r-p-h-pharmacies-list" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyHeader = () => import('../../src/components/v2/templates/RPHPharmacyHeader/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-header" */).then(c => wrapFunctional(c.default || c))
export const RPHPopularProductBlock = () => import('../../src/components/v2/templates/RPHPopularProductBlock/index.vue' /* webpackChunkName: "components/r-p-h-popular-product-block" */).then(c => wrapFunctional(c.default || c))
export const RPHPortal = () => import('../../src/components/v2/templates/RPHPortal/index.vue' /* webpackChunkName: "components/r-p-h-portal" */).then(c => wrapFunctional(c.default || c))
export const RPHProducerHeader = () => import('../../src/components/v2/templates/RPHProducerHeader/index.vue' /* webpackChunkName: "components/r-p-h-producer-header" */).then(c => wrapFunctional(c.default || c))
export const RPHProduct = () => import('../../src/components/v2/templates/RPHProduct/index.vue' /* webpackChunkName: "components/r-p-h-product" */).then(c => wrapFunctional(c.default || c))
export const RPHProductMain = () => import('../../src/components/v2/templates/RPHProductMain/index.vue' /* webpackChunkName: "components/r-p-h-product-main" */).then(c => wrapFunctional(c.default || c))
export const RPHProductSpecsContainer = () => import('../../src/components/v2/templates/RPHProductSpecsContainer/index.vue' /* webpackChunkName: "components/r-p-h-product-specs-container" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileAddress = () => import('../../src/components/v2/templates/RPHProfileAddress/index.vue' /* webpackChunkName: "components/r-p-h-profile-address" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileCards = () => import('../../src/components/v2/templates/RPHProfileCards/index.vue' /* webpackChunkName: "components/r-p-h-profile-cards" */).then(c => wrapFunctional(c.default || c))
export const RPHProfileOrder = () => import('../../src/components/v2/templates/RPHProfileOrder/index.vue' /* webpackChunkName: "components/r-p-h-profile-order" */).then(c => wrapFunctional(c.default || c))
export const RPHRelatedBlock = () => import('../../src/components/v2/templates/RPHRelatedBlock/RPHRelatedBlock.vue' /* webpackChunkName: "components/r-p-h-related-block" */).then(c => wrapFunctional(c.default || c))
export const RPHSearchBlock = () => import('../../src/components/v2/templates/RPHSearchBlock/index.vue' /* webpackChunkName: "components/r-p-h-search-block" */).then(c => wrapFunctional(c.default || c))
export const RPHSeoText = () => import('../../src/components/v2/templates/RPHSeoText/index.vue' /* webpackChunkName: "components/r-p-h-seo-text" */).then(c => wrapFunctional(c.default || c))
export const RPHAdultAccessModal = () => import('../../src/components/v2/molecules/RPHModals/RPHAdultAccessModal/index.vue' /* webpackChunkName: "components/r-p-h-adult-access-modal" */).then(c => wrapFunctional(c.default || c))
export const RPHFindAddress = () => import('../../src/components/v2/molecules/RPHModals/RPHFindAddress/index.vue' /* webpackChunkName: "components/r-p-h-find-address" */).then(c => wrapFunctional(c.default || c))
export const RPHLogoutConfirm = () => import('../../src/components/v2/molecules/RPHModals/RPHLogoutConfirm/index.vue' /* webpackChunkName: "components/r-p-h-logout-confirm" */).then(c => wrapFunctional(c.default || c))
export const RPHModalWindow = () => import('../../src/components/v2/molecules/RPHModals/RPHModalWindow/index.vue' /* webpackChunkName: "components/r-p-h-modal-window" */).then(c => wrapFunctional(c.default || c))
export const RPHPharmacyReport = () => import('../../src/components/v2/molecules/RPHModals/RPHPharmacyReport/index.vue' /* webpackChunkName: "components/r-p-h-pharmacy-report" */).then(c => wrapFunctional(c.default || c))
export const RPHProductConfirm = () => import('../../src/components/v2/molecules/RPHModals/RPHProductConfirm/index.vue' /* webpackChunkName: "components/r-p-h-product-confirm" */).then(c => wrapFunctional(c.default || c))
export const RPHReviewForm = () => import('../../src/components/v2/molecules/RPHModals/RPHReviewForm/index.vue' /* webpackChunkName: "components/r-p-h-review-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
