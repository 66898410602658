import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _23cdd4fb = () => interopDefault(import('../src/pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _5f6e1464 = () => interopDefault(import('../src/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _473d099e = () => interopDefault(import('../src/pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _0aa171b4 = () => interopDefault(import('../src/pages/disease/index.vue' /* webpackChunkName: "pages/disease/index" */))
const _7303e94e = () => interopDefault(import('../src/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _48fc0d68 = () => interopDefault(import('../src/pages/hello-world.vue' /* webpackChunkName: "pages/hello-world" */))
const _b6538d70 = () => interopDefault(import('../src/pages/inns/index.vue' /* webpackChunkName: "pages/inns/index" */))
const _52fd1119 = () => interopDefault(import('../src/pages/institutions/index.vue' /* webpackChunkName: "pages/institutions/index" */))
const _1e62819e = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _7979452f = () => interopDefault(import('../src/pages/pharmacies/index.vue' /* webpackChunkName: "pages/pharmacies/index" */))
const _92a5f44e = () => interopDefault(import('../src/pages/producers/index.vue' /* webpackChunkName: "pages/producers/index" */))
const _51ccff10 = () => interopDefault(import('../src/pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _072076c3 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _2f07d496 = () => interopDefault(import('../src/pages/cabinet/cabinet.vue' /* webpackChunkName: "pages/cabinet/cabinet" */))
const _17047cdb = () => interopDefault(import('../src/pages/cabinet/cabinet/Address.vue' /* webpackChunkName: "pages/cabinet/cabinet/Address" */))
const _38df7eec = () => interopDefault(import('../src/pages/cabinet/cabinet/Cards.vue' /* webpackChunkName: "pages/cabinet/cabinet/Cards" */))
const _4c6e1a16 = () => interopDefault(import('../src/pages/cabinet/cabinet/Order.vue' /* webpackChunkName: "pages/cabinet/cabinet/Order" */))
const _73b38810 = () => interopDefault(import('../src/pages/cabinet/cabinet/Profile.vue' /* webpackChunkName: "pages/cabinet/cabinet/Profile" */))
const _d26c2b08 = () => interopDefault(import('../src/pages/order/error.vue' /* webpackChunkName: "pages/order/error" */))
const _f0647854 = () => interopDefault(import('../src/pages/order/post3ds.vue' /* webpackChunkName: "pages/order/post3ds" */))
const _4489dbf7 = () => interopDefault(import('../src/pages/order/success.vue' /* webpackChunkName: "pages/order/success" */))
const _1bb8d387 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2dc1f7b3 = () => interopDefault(import('../src/pages/analogs/_slug.vue' /* webpackChunkName: "pages/analogs/_slug" */))
const _1ea00228 = () => interopDefault(import('../src/pages/content/_slug/index.vue' /* webpackChunkName: "pages/content/_slug/index" */))
const _08ead06c = () => interopDefault(import('../src/pages/disease/_slug.vue' /* webpackChunkName: "pages/disease/_slug" */))
const _56da1e83 = () => interopDefault(import('../src/pages/inns/_slug/index.vue' /* webpackChunkName: "pages/inns/_slug/index" */))
const _21cfc42e = () => interopDefault(import('../src/pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _2ab80378 = () => interopDefault(import('../src/pages/preparaty/_slug.vue' /* webpackChunkName: "pages/preparaty/_slug" */))
const _c4610b58 = () => interopDefault(import('../src/pages/producers/_slug/index.vue' /* webpackChunkName: "pages/producers/_slug/index" */))
const _563f381f = () => interopDefault(import('../src/pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _6ff0d2c0 = () => interopDefault(import('../src/pages/institutions/_institution/about.vue' /* webpackChunkName: "pages/institutions/_institution/about" */))
const _0ab2e720 = () => interopDefault(import('../src/pages/institutions/_institution/contacts.vue' /* webpackChunkName: "pages/institutions/_institution/contacts" */))
const _77e1c46d = () => interopDefault(import('../src/pages/pharmacies/_pharmacy/about.vue' /* webpackChunkName: "pages/pharmacies/_pharmacy/about" */))
const _1a929fa3 = () => interopDefault(import('../src/pages/pharmacies/_pharmacy/contacts.vue' /* webpackChunkName: "pages/pharmacies/_pharmacy/contacts" */))
const _58a98bfb = () => interopDefault(import('../src/pages/pharmacies/_pharmacy/reviews.vue' /* webpackChunkName: "pages/pharmacies/_pharmacy/reviews" */))
const _e41be786 = () => interopDefault(import('../src/pages/institutions/_institution/_slug.vue' /* webpackChunkName: "pages/institutions/_institution/_slug" */))
const _d43a042c = () => interopDefault(import('../src/pages/pharmacies/_pharmacy/_slug.vue' /* webpackChunkName: "pages/pharmacies/_pharmacy/_slug" */))
const _1c4213d6 = () => interopDefault(import('../src/pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _3a79d6bd = () => interopDefault(import('../src/pages/_category/_product.vue' /* webpackChunkName: "pages/_category/_product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _23cdd4fb,
    name: "cart"
  }, {
    path: "/checkout",
    component: _5f6e1464,
    name: "checkout"
  }, {
    path: "/contacts",
    component: _473d099e,
    name: "contacts"
  }, {
    path: "/disease",
    component: _0aa171b4,
    name: "disease"
  }, {
    path: "/faq",
    component: _7303e94e,
    name: "faq"
  }, {
    path: "/hello-world",
    component: _48fc0d68,
    name: "hello-world"
  }, {
    path: "/inns",
    component: _b6538d70,
    name: "inns"
  }, {
    path: "/institutions",
    component: _52fd1119,
    name: "institutions"
  }, {
    path: "/news",
    component: _1e62819e,
    name: "news"
  }, {
    path: "/pharmacies",
    component: _7979452f,
    name: "pharmacies"
  }, {
    path: "/producers",
    component: _92a5f44e,
    name: "producers"
  }, {
    path: "/reviews",
    component: _51ccff10,
    name: "reviews"
  }, {
    path: "/search",
    component: _072076c3,
    name: "search"
  }, {
    path: "/cabinet/cabinet",
    component: _2f07d496,
    name: "cabinet-cabinet",
    children: [{
      path: "Address",
      component: _17047cdb,
      name: "cabinet-cabinet-Address"
    }, {
      path: "Cards",
      component: _38df7eec,
      name: "cabinet-cabinet-Cards"
    }, {
      path: "Order",
      component: _4c6e1a16,
      name: "cabinet-cabinet-Order"
    }, {
      path: "Profile",
      component: _73b38810,
      name: "cabinet-cabinet-Profile"
    }]
  }, {
    path: "/order/error",
    component: _d26c2b08,
    name: "order-error"
  }, {
    path: "/order/post3ds",
    component: _f0647854,
    name: "order-post3ds"
  }, {
    path: "/order/success",
    component: _4489dbf7,
    name: "order-success"
  }, {
    path: "/",
    component: _1bb8d387,
    name: "index"
  }, {
    path: "/analogs/:slug?",
    component: _2dc1f7b3,
    name: "analogs-slug"
  }, {
    path: "/content/:slug",
    component: _1ea00228,
    name: "content-slug"
  }, {
    path: "/disease/:slug",
    component: _08ead06c,
    name: "disease-slug"
  }, {
    path: "/inns/:slug",
    component: _56da1e83,
    name: "inns-slug"
  }, {
    path: "/news/:slug",
    component: _21cfc42e,
    name: "news-slug"
  }, {
    path: "/preparaty/:slug?",
    component: _2ab80378,
    name: "preparaty-slug"
  }, {
    path: "/producers/:slug",
    component: _c4610b58,
    name: "producers-slug"
  }, {
    path: "/product/:slug?",
    component: _563f381f,
    name: "product-slug"
  }, {
    path: "/institutions/:institution/about",
    component: _6ff0d2c0,
    name: "institutions-institution-about"
  }, {
    path: "/institutions/:institution/contacts",
    component: _0ab2e720,
    name: "institutions-institution-contacts"
  }, {
    path: "/pharmacies/:pharmacy/about",
    component: _77e1c46d,
    name: "pharmacies-pharmacy-about"
  }, {
    path: "/pharmacies/:pharmacy/contacts",
    component: _1a929fa3,
    name: "pharmacies-pharmacy-contacts"
  }, {
    path: "/pharmacies/:pharmacy/reviews",
    component: _58a98bfb,
    name: "pharmacies-pharmacy-reviews"
  }, {
    path: "/institutions/:institution/:slug?",
    component: _e41be786,
    name: "institutions-institution-slug"
  }, {
    path: "/pharmacies/:pharmacy/:slug?",
    component: _d43a042c,
    name: "pharmacies-pharmacy-slug"
  }, {
    path: "/:category",
    component: _1c4213d6,
    name: "category"
  }, {
    path: "/:category/:product",
    component: _3a79d6bd,
    name: "category-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
